const SwitchToggleIconSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M6.66666 5.83342C4.36548 5.83342 2.5 7.6989 2.5 10.0001C2.5 12.3013 4.36548 14.1668 6.66666 14.1668H13.3333C15.6345 14.1668 17.5 12.3013 17.5 10.0001C17.5 7.6989 15.6345 5.83342 13.3333 5.83342H6.66666ZM6.66666 4.16675H13.3333C16.555 4.16675 19.1667 6.77842 19.1667 10.0001C19.1667 13.2218 16.555 15.8334 13.3333 15.8334H6.66666C3.445 15.8334 0.833328 13.2218 0.833328 10.0001C0.833328 6.77842 3.445 4.16675 6.66666 4.16675ZM6.66666 12.5001C5.28595 12.5001 4.16666 11.3808 4.16666 10.0001C4.16666 8.61933 5.28595 7.50008 6.66666 7.50008C8.04737 7.50008 9.16666 8.61933 9.16666 10.0001C9.16666 11.3808 8.04737 12.5001 6.66666 12.5001Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default SwitchToggleIconSVG;
