const EmptySquareIconSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
            preserveAspectRatio="none"
        >
            <path
                d="M12.9168 15.1665C12.2265 15.1665 11.6668 15.7261 11.6668 16.4165C11.6668 17.1069 12.2265 17.6665 12.9168 17.6665C13.6072 17.6665 14.1668 17.1069 14.1668 16.4165C14.1668 15.7261 13.6072 15.1665 12.9168 15.1665ZM12.9168 9.74984C12.2265 9.74984 11.6668 10.3095 11.6668 10.9998C11.6668 11.6902 12.2265 12.2498 12.9168 12.2498C13.6072 12.2498 14.1668 11.6902 14.1668 10.9998C14.1668 10.3095 13.6072 9.74984 12.9168 9.74984ZM11.6668 5.58317C11.6668 4.89284 12.2265 4.33317 12.9168 4.33317C13.6072 4.33317 14.1668 4.89284 14.1668 5.58317C14.1668 6.27351 13.6072 6.83317 12.9168 6.83317C12.2265 6.83317 11.6668 6.2735 11.6668 5.58317ZM7.08348 15.1665C6.39314 15.1665 5.83348 15.7261 5.83348 16.4165C5.83348 17.1069 6.39314 17.6665 7.08348 17.6665C7.77381 17.6665 8.33348 17.1069 8.33348 16.4165C8.33348 15.7261 7.77381 15.1665 7.08348 15.1665ZM5.83348 10.9998C5.83348 10.3095 6.39314 9.74984 7.08348 9.74984C7.77381 9.74984 8.33348 10.3095 8.33348 10.9998C8.33348 11.6902 7.77381 12.2498 7.08348 12.2498C6.39314 12.2498 5.83348 11.6902 5.83348 10.9998ZM7.08348 4.33317C6.39314 4.33317 5.83348 4.89284 5.83348 5.58317C5.83348 6.2735 6.39314 6.83317 7.08348 6.83317C7.77381 6.83317 8.33348 6.2735 8.33348 5.58317C8.33348 4.89284 7.77381 4.33317 7.08348 4.33317Z"
                fill="white"
            ></path>
        </svg>
    );
};

export default EmptySquareIconSVG;
