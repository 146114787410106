const FileIconSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M12.3573 6.46442L7.64317 11.1784C7.31773 11.5039 7.31773 12.0315 7.64317 12.3569C7.9686 12.6824 8.49625 12.6824 8.82167 12.3569L13.5358 7.64293C14.512 6.66662 14.512 5.08371 13.5358 4.10739C12.5594 3.13108 10.9765 3.13108 10.0002 4.10739L5.28615 8.82144C3.65896 10.4486 3.65896 13.0868 5.28615 14.714C6.91333 16.3412 9.5515 16.3412 11.1787 14.714L15.8928 9.99994L17.0713 11.1784L12.3573 15.8925C10.0792 18.1705 6.3857 18.1705 4.10764 15.8925C1.82958 13.6144 1.82958 9.92103 4.10764 7.64293L8.82167 2.92888C10.4488 1.3017 13.0871 1.3017 14.7143 2.92888C16.3414 4.55607 16.3414 7.19425 14.7143 8.82144L10.0002 13.5355C9.02392 14.5118 7.44097 14.5118 6.46466 13.5355C5.48835 12.5592 5.48835 10.9763 6.46466 9.99994L11.1787 5.28591L12.3573 6.46442Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default FileIconSVG;
