const DragIconVerticalSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
        >
            <g id="draggable">
                <path
                    id="Vector"
                    d="M12.9168 15.1665C12.2265 15.1665 11.6668 15.7261 11.6668 16.4165C11.6668 17.1069 12.2265 17.6665 12.9168 17.6665C13.6072 17.6665 14.1668 17.1069 14.1668 16.4165C14.1668 15.7261 13.6072 15.1665 12.9168 15.1665ZM12.9168 9.74984C12.2265 9.74984 11.6668 10.3095 11.6668 10.9998C11.6668 11.6902 12.2265 12.2498 12.9168 12.2498C13.6072 12.2498 14.1668 11.6902 14.1668 10.9998C14.1668 10.3095 13.6072 9.74984 12.9168 9.74984ZM11.6668 5.58317C11.6668 4.89284 12.2265 4.33317 12.9168 4.33317C13.6072 4.33317 14.1668 4.89284 14.1668 5.58317C14.1668 6.27351 13.6072 6.83317 12.9168 6.83317C12.2265 6.83317 11.6668 6.2735 11.6668 5.58317ZM7.08351 15.1665C6.39317 15.1665 5.83351 15.7261 5.83351 16.4165C5.83351 17.1069 6.39317 17.6665 7.08351 17.6665C7.77384 17.6665 8.33351 17.1069 8.33351 16.4165C8.33351 15.7261 7.77384 15.1665 7.08351 15.1665ZM5.83351 10.9998C5.83351 10.3095 6.39317 9.74984 7.08351 9.74984C7.77384 9.74984 8.33351 10.3095 8.33351 10.9998C8.33351 11.6902 7.77384 12.2498 7.08351 12.2498C6.39317 12.2498 5.83351 11.6902 5.83351 10.9998ZM7.08351 4.33317C6.39317 4.33317 5.83351 4.89284 5.83351 5.58317C5.83351 6.2735 6.39317 6.83317 7.08351 6.83317C7.77384 6.83317 8.33351 6.2735 8.33351 5.58317C8.33351 4.89284 7.77384 4.33317 7.08351 4.33317Z"
                    fill="#6B7280"
                />
            </g>
        </svg>
    );
};

export default DragIconVerticalSVG;
