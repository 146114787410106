const RadioSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M10 18.3334C5.39763 18.3334 1.66667 14.6024 1.66667 10.0001C1.66667 5.39771 5.39763 1.66675 10 1.66675C14.6023 1.66675 18.3333 5.39771 18.3333 10.0001C18.3333 14.6024 14.6023 18.3334 10 18.3334ZM10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31818 13.6819 3.33341 10 3.33341C6.3181 3.33341 3.33334 6.31818 3.33334 10.0001C3.33334 13.682 6.3181 16.6667 10 16.6667ZM10 14.1667C7.69882 14.1667 5.83334 12.3012 5.83334 10.0001C5.83334 7.6989 7.69882 5.83341 10 5.83341C12.3012 5.83341 14.1667 7.6989 14.1667 10.0001C14.1667 12.3012 12.3012 14.1667 10 14.1667Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default RadioSVG;
