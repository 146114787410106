const CloseIconSmallSVG = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-3.5 h-3.5 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M8.44946 7L9.29716 7.8477C9.39336 7.94154 9.47018 8.05339 9.52324 8.17689L9.06383 8.37424L9.52324 8.17689C9.57695 8.30194 9.60523 8.43644 9.60641 8.57254C9.6076 8.70864 9.58166 8.84362 9.53012 8.96958C9.47859 9.09555 9.40248 9.20999 9.30624 9.30623L8.44946 7ZM8.44946 7L9.29973 6.14973L9.29978 6.14978L9.30582 6.14353C9.49253 5.95021 9.59585 5.6913 9.59351 5.42254C9.59118 5.15379 9.48338 4.89671 9.29333 4.70667C9.10329 4.51662 8.84621 4.40882 8.57746 4.40649C8.3087 4.40415 8.04979 4.50747 7.85647 4.69418L7.85642 4.69413L7.85027 4.70027L7 5.55054L6.14973 4.70027L6.14978 4.70022L6.14353 4.69418C5.95022 4.50747 5.6913 4.40415 5.42255 4.40649C5.1538 4.40882 4.89671 4.51662 4.70667 4.70667L5.06022 5.06022L4.70667 4.70667C4.51662 4.89671 4.40883 5.15379 4.40649 5.42254C4.40416 5.6913 4.50747 5.95021 4.69418 6.14353L4.69413 6.14358L4.70027 6.14973L5.55055 7L4.70027 7.85027L4.70022 7.85022L4.69418 7.85647C4.50747 8.04978 4.40416 8.3087 4.40649 8.57745C4.40883 8.8462 4.51662 9.10329 4.70667 9.29333C4.89671 9.48338 5.1538 9.59117 5.42255 9.59351C5.6913 9.59584 5.95022 9.49253 6.14353 9.30582L6.14359 9.30587L6.14973 9.29973L7 8.44945L7.84771 9.29716C7.94154 9.39336 8.05339 9.47018 8.17689 9.52323L8.37424 9.06383L8.17689 9.52323C8.30195 9.57695 8.43645 9.60523 8.57254 9.60641C8.70865 9.60759 8.84362 9.58166 8.96958 9.53012C9.09555 9.47858 9.21 9.40247 9.30624 9.30624L8.44946 7ZM4.36104 3.05052C5.1421 2.52863 6.06036 2.25005 6.99972 2.25C8.25913 2.25145 9.46653 2.75239 10.3571 3.64293C11.2477 4.53353 11.7486 5.74105 11.75 7.00055C11.7499 7.93982 11.4713 8.85798 10.9495 9.63896C10.4275 10.4201 9.6857 11.0289 8.81775 11.3884C7.9498 11.7479 6.99473 11.842 6.07332 11.6587C5.15191 11.4754 4.30554 11.0231 3.64124 10.3588C2.97695 9.69446 2.52455 8.84809 2.34127 7.92668C2.15799 7.00527 2.25206 6.0502 2.61157 5.18225C2.97109 4.3143 3.57991 3.57246 4.36104 3.05052Z"
                fill="#9CA3AF"
                stroke="#9CA3AF"
            ></path>
        </svg>
    );
};

export default CloseIconSmallSVG;
