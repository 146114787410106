import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import DraggableArea from "./DraggableArea";
import DraggableItemsList from "./DraggableItemsList";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import customSurvey from "../customSurvey.json";
import { useSurveyStore } from "./stores/useSurveyStore"; // Импорт хранилища
import axios from "axios";
import ConditionsModalComponent from "./ConditionsModalComponent";
import SettingsComponent from "./SettingsComponent";
import EditIconSVG from "./icons/EditIconSVG";
import { getCorrectTypes } from "./functions.js";

const NewSurveyComponent = ({ isAdmin, surveyData, editMode }) => {
    const {
        surveyJson,
        setSurveyJson,
        setDroppedItems,
        surveyTitle,
        setSurveyTitle,
        surveyId,
        setSurveyId,
        settings,
        setSettings,
    } = useSurveyStore();

    const [survey, setSurvey] = useState(null);
    const [mode, setMode] = useState("surveyCreation");
    const [surveyTitleEditMode, setSurveyTitleEditMode] = useState(false);
    const [surveyCover, setSurveyCover] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");

    useEffect(() => {
        console.log("surveyData", surveyData);

        if (surveyData.survey_id) {
            const { privacy, regularity } = surveyData;
            setSurveyJson(surveyData);
            setSettings({
                privacy: JSON.parse(privacy),
                regularity: JSON.parse(regularity),
            });
            setDroppedItems(getDroppedItemsFormSurveyData());
            setSurveyTitle(surveyData?.title || "Новый опрос");
            setSurveyId(surveyData?.survey_id);
        }
    }, [
        surveyData,
        setSurveyJson,
        setDroppedItems,
        setSurveyTitle,
        setSurveyId,
    ]);

    function getDroppedItemsFormSurveyData() {
        if (surveyData?.questions) {
            const items = surveyData.questions.map((q) => ({
                ...q,
                type_id: getCorrectTypeId(q.type),
            }));
            return items;
        } else {
            return [];
        }
    }

    function getCorrectTypeId(type) {
        switch (type) {
            case "checkbox":
                return 0;
            case "radiogroup":
                return 1;
            case "text":
                return 2;
            case "comment":
                return 3;
            case "boolean":
                return 4;
            case "rating":
                return 5;
            case "file":
                return 6;
            case "dropdown":
                return 7;
            default:
                return 0;
        }
    }

    const createSurveyPreview = () => {
        setMode("surveyPreview");
        // createSurveyJsonFromNodes();
    };

    useEffect(() => {
        if (surveyJson) {
            const { elements } = surveyJson;

            const mappedElements = elements?.length
                ? elements.map((el) => {
                      return {
                          ...el,
                          type: getCorrectTypes(el.type),
                      };
                  })
                : [];
            // console.log("mappedElements", mappedElements);

            const survey = new Model({
                ...surveyJson,
                elements: mappedElements,
            });
            survey.applyTheme(customSurvey);
            setSurvey(survey);
        }
    }, [surveyJson]);

    const handleTitleChange = (e) => {
        setSurveyTitle(e.target.value);
    };

    const getActiveClass = (el) => {
        if (mode === el) {
            return "text-[#e02424]";
        } else {
            return "text-[#111928]";
        }
    };
    const onComplete = async (survey) => {
        const surveyData = survey.data;
        console.log("Survey results: ", surveyData);
        try {
            console.log("surveyJson", surveyJson);
            const req = await fetch(" /save-answers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-TOKEN": token,
                },
                body: JSON.stringify(surveyJson),
            });

            // Получаем и выводим JSON-ответ от сервера
            const res = await req.json();
            console.log("Response:", res);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    survey?.onCompleting.add(function (survey, options) {
        options.allowComplete = false; // Полностью отключить завершение опроса в режиме предпросмотра
    });

    const [conditionsSettings, setConditionsSettings] = useState(null);

    function handleConditionsModalOpenClick(item, index) {
        setIsModalOpen(true);
        setConditionsSettings({ ...item, index });
    }

    const sendSurvey = async () => {
        try {
            // Create a FormData object
            surveyJson.title = surveyTitle;
            surveyJson.survey_id = surveyId;
            const formData = new FormData();

            // Append the survey data as JSON
            formData.append(
                "surveyJson",
                JSON.stringify({ ...surveyJson, ...settings })
            );

            // Append the survey cover file
            formData.append("cover", surveyCover);

            // Log the FormData content (for debugging purposes)
            for (let pair of formData.entries()) {
                // console.log(pair[0] + ": " + pair[1]);
            }

            // Send the POST request with FormData
            const req = await fetch(
                editMode ? "/surveys/add/save" : "/surveys/add/save",
                {
                    method: "POST",
                    headers: {
                        "X-CSRF-TOKEN": token,
                    },
                    body: formData,
                }
            );

            // Get and log the JSON response from the server
            const res = await req.json();
            // console.log("Response:", res);
            // return res;
            if (res.status === "success") {
                alert("Опрос успешно создан");
                window.location.href = "/";
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // useEffect(() => {
    //     if (surveyJson) {
    //         console.log("surveyJson in UseEffect", surveyJson);
    //     }
    // }, [surveyJson]);

    function handleConditionComplete(
        item,
        selectedQuestion,
        selectedCondition,
        selectedOption,
        operators
    ) {
        // const surveyJson = getSurveyJson();
        // console.log("handleConditionComplete", surveyJson);
        console.log("operators", operators);
        if (item) {
            // console.log(item);

            // Глубокая копия surveyJson для избежания побочных эффектов
            const updatedSurveyJson = JSON.parse(JSON.stringify(surveyJson));

            // Найти нужный вопрос в surveyJson
            const questionInSurvey = updatedSurveyJson?.elements?.find(
                (element) => element.id === item.id
            );
            console.log("questionInSurvey", questionInSurvey);

            if (questionInSurvey) {
                // Обновляем свойство visibleIf у найденного вопроса
                let string = "";
                if (selectedQuestion.length) {
                    selectedQuestion.forEach((question, index) => {
                        string += `${
                            index !== 0
                                ? " " + operators[index].value + " "
                                : ""
                        }{${question.name}} ${
                            selectedCondition[index]?.value
                        } '${selectedOption[index]?.value}'`;
                    });
                }
                questionInSurvey.visibleIf = string;

                // Лог для проверки обновлённого surveyJson
                console.log(
                    "updatedSurveyJson in handleConditionComplete",
                    updatedSurveyJson
                );

                // Сохраняем обновленный JSON в Zustand, который автоматически пересоберет surveyJson
                setSurveyJson(updatedSurveyJson);
            }
        }
    }

    const [users, setUsers] = useState([]);
    const [departments, setDepartments] = useState([]);
    useEffect(() => {
        (async () => {
            await getUsers();
        })();
    }, []);
    async function getUsers() {
        const response = await axios.get("/jsonUsers");
        setUsers(response.data);
    }
    useEffect(() => {
        if (users.length) {
            const departmentMap = {};
            let idCounter = 0;
            users.forEach((user) => {
                const departmentName = user.deportament;
                const departmentKey = departmentName?.toLowerCase(); // Приводим к нижнему регистру для проверки
                // Если департамент еще не был добавлен, создаем новый объект для него
                if (!departmentMap[departmentKey] && departmentName) {
                    departmentMap[departmentKey] = {
                        value: { id: idCounter, name: departmentName }, // Сохраняем оригинальный регистр
                        label: departmentName,
                    };
                    idCounter++;
                }
            });
            // Преобразуем departmentMap в массив
            const departmentsArray = Object.values(departmentMap);
            setDepartments(departmentsArray);
        }
    }, [users]);

    useEffect(() => {
        if (surveyJson) {
            // console.log("surveyJson in UseEffect", surveyJson);
        }
    }, [surveyJson]);

    return (
        <>
            {isAdmin && (
                <DndProvider backend={HTML5Backend}>
                    <div className="flex justify-between items-center py-6">
                        {/* Заголовок */}
                        <div className="flex-grow-0 flex-shrink-0 relative">
                            {!surveyTitleEditMode ? (
                                <div className="flex gap-2 items-center">
                                    <p className=" text-2xl font-medium text-left text-[#1f2a37]">
                                        {surveyTitle}
                                    </p>
                                    <EditIconSVG
                                        onClick={() =>
                                            setSurveyTitleEditMode(true)
                                        }
                                    />
                                </div>
                            ) : (
                                <input
                                    className="border-0"
                                    onChange={handleTitleChange}
                                    value={surveyTitle}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            setSurveyTitleEditMode(false);
                                        }
                                    }}
                                />
                            )}
                        </div>
                        <div className="flex justify-between items-center gap-3 ">
                            <div className="flex justify-center items-center gap-2 px-3 py-2.5 rounded-lg border border-[#1f2a37]">
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="hidden"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            // Здесь происходит сохранение файла в переменную surveyCover
                                            setSurveyCover(file);
                                            console.log(
                                                "Файл загружен:",
                                                surveyCover
                                            );
                                        }
                                    }}
                                />
                                <label
                                    htmlFor="file-upload"
                                    className="flex cursor-pointer"
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                            d="M17.5 12.5V15H20V16.6667H17.5V19.1667H15.8333V16.6667H13.3333V15H15.8333V12.5H17.5ZM17.5068 2.5C17.9633 2.5 18.3333 2.87079 18.3333 3.32783V10.8333H16.6666V4.16667H3.33329V15.8325L11.6666 7.5L14.1666 10V12.3575L11.6666 9.857L5.68913 15.8333H11.6666V17.5H2.49313C2.03667 17.5 1.66663 17.1293 1.66663 16.6722V3.32783C1.66663 2.87063 2.04605 2.5 2.49313 2.5H17.5068ZM6.66663 5.83333C7.5871 5.83333 8.33329 6.57952 8.33329 7.5C8.33329 8.4205 7.5871 9.16667 6.66663 9.16667C5.74615 9.16667 4.99996 8.4205 4.99996 7.5C4.99996 6.57952 5.74615 5.83333 6.66663 5.83333Z"
                                            fill="#111928"
                                        ></path>
                                    </svg>
                                </label>
                            </div>

                            <div
                                role="button"
                                className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2 px-5 py-2.5 rounded-lg bg-[#aa1d2b]"
                                onClick={() => sendSurvey()}
                            >
                                <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">
                                    {editMode ? "Сохранить" : "Опубликовать"}
                                </p>
                            </div>
                        </div>
                    </div>
                    {surveyCover && (
                        <div className="my-4 flex justify-center items-center flex-grow-0 flex-shrink-0 relative">
                            <img
                                src={URL.createObjectURL(surveyCover)}
                                alt="Uploaded Cover"
                                className="max-w-full h-auto rounded-lg"
                            />
                        </div>
                    )}

                    <div className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 gap-8">
                        <div className="flex justify-start gap-8">
                            <div
                                role="button"
                                onClick={() => setMode("surveyCreation")}
                                className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 relative"
                            >
                                <p
                                    id="surveyCreation"
                                    className={
                                        "flex-grow-0 flex-shrink-0 text-base font-medium text-center " +
                                        getActiveClass("surveyCreation")
                                    }
                                >
                                    Констуктор
                                </p>
                            </div>
                            <div
                                role="button"
                                className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 relative"
                                onClick={createSurveyPreview}
                            >
                                <p
                                    id="surveyPreview"
                                    className={
                                        "flex-grow-0 flex-shrink-0 text-base font-medium text-center " +
                                        getActiveClass("surveyPreview")
                                    }
                                >
                                    Предпросмотр
                                </p>
                            </div>
                            <div
                                className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 relative"
                                onClick={() => setMode("surveySettings")}
                                role="button"
                            >
                                <p
                                    id="surveySettings"
                                    className={
                                        "flex-grow-0 flex-shrink-0 text-base font-medium text-center " +
                                        getActiveClass("surveySettings")
                                    }
                                >
                                    Настройки
                                </p>
                            </div>
                            <div className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 relative">
                                <p
                                    id="surveyReplies"
                                    className={
                                        "flex-grow-0 flex-shrink-0 text-base font-medium text-center " +
                                        getActiveClass("surveyReplies")
                                    }
                                >
                                    Ответы
                                </p>
                            </div>
                        </div>
                        {mode === "surveyCreation" && (
                            <div className="flex justify-start gap-8">
                                <DraggableItemsList />
                                <DraggableArea
                                    handleConditionsModalOpenClick={
                                        handleConditionsModalOpenClick
                                    }
                                />
                            </div>
                        )}
                        {mode === "surveyPreview" &&
                            surveyJson.elements?.length &&
                            survey && (
                                <>
                                    <div
                                        className="flex flex-grow-0 flex-shrink-0 w-[948px] p-8 rounded-2xl bg-white"
                                        style={{
                                            boxShadow:
                                                "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                                        }}
                                    >
                                        <Survey
                                            model={survey}
                                            onComplete={onComplete} //это нужно не в режиме предпросмотра, а уже при реальном ответе
                                        />
                                    </div>
                                </>
                            )}
                        {mode === "surveySettings" && (
                            <SettingsComponent
                                departments={departments}
                                users={users}
                            />
                        )}
                    </div>
                </DndProvider>
            )}
            {isModalOpen && (
                <ConditionsModalComponent
                    setIsModalOpen={setIsModalOpen}
                    conditionsSettingsFromProps={conditionsSettings}
                    handleConditionComplete={handleConditionComplete}
                />
            )}
        </>
    );
};

export default NewSurveyComponent;
