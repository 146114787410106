const LongTextIconSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M0.833328 1.66675V4.16675H2.49999V3.33341H4.16666V7.50008H2.91666V9.16675H7.08333V7.50008H5.83333V3.33341H7.49999V4.16675H9.16666V1.66675H0.833328ZM17.5 2.50008H11.6667V4.16675H16.6667V15.8334H3.33333V11.6667H1.66666V16.6667C1.66666 17.127 2.03976 17.5001 2.49999 17.5001H17.5C17.9602 17.5001 18.3333 17.127 18.3333 16.6667V3.33341C18.3333 2.87318 17.9602 2.50008 17.5 2.50008Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default LongTextIconSVG;
