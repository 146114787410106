export function formatDateToDateTime(date, setEndOfDay = false) {
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2); // Месяцы в JS начинаются с 0
    let day = ("0" + date.getDate()).slice(-2);

    // Если флаг setEndOfDay установлен в true, время будет 23:59:59
    let hours = setEndOfDay ? "23" : ("0" + date.getHours()).slice(-2);
    let minutes = setEndOfDay ? "59" : ("0" + date.getMinutes()).slice(-2);
    let seconds = setEndOfDay ? "59" : ("0" + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export const getCorrectTypes = (type) => {
    // console.log("getCorrectTypes called with type:", type);

    switch (type) {
        case "multiple-choice":
            return "checkbox";
        case "single-choice":
            return "radiogroup";
        case "short-text":
            return "text";
        case "long-text":
            return "comment";
        case "yes-no":
            return "boolean";
        case "rating":
            return "rating";
        case "file":
            return "file";
        case "dropdown":
            return "dropdown";
        case "panel":
            return "panel";
        default:
            return "text";
    }
};
