import "./bootstrap";
import React from "react";

import Alpine from "alpinejs";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import customSurvey from "./customSurvey.json";
import "./App.css";
import { useState } from "react";
import { createRoot } from "react-dom/client";
import NewSurveyComponent from "./components/NewSurveyComponent";
import { getCorrectTypes } from "./components/functions";

// console.log(customSurvey);

const surveyJson = {
    elements: [
        {
            name: "FirstName",
            title: "Enter your first name:",
            type: "text",
            hideNumber: true,
            isRequired: true,
        },
        {
            name: "LastName",
            title: "Enter your last name:",
            type: "text",
            hideNumber: true,
        },
        {
            name: "Age",
            title: "Enter your age:",
            type: "text",
            inputType: "number", // Указывает тип ввода HTML как number
            validators: [
                {
                    type: "numeric", // Валидатор для проверки числового значения
                    minValue: 0, // Минимальное значение
                    maxValue: 120, // Максимальное значение (например, для возраста)
                },
            ],
            hideNumber: true,
        },
        {
            name: "Gender",
            title: "Select your gender:",
            type: "radiogroup",
            choices: ["Male", "Female", "Other"],
            hideNumber: true,
        },
        {
            name: "Hobbies",
            title: "Select your hobbies:",
            type: "checkbox",
            choices: ["Reading", "Sports", "Music", "Traveling"],
            hideNumber: true,
        },
        {
            name: "Country",
            title: "Select your country:",
            type: "dropdown",
            choices: ["USA", "Canada", "UK", "Australia"],
            hideNumber: true,
        },
        {
            name: "Comments",
            title: "Any additional comments:",
            type: "comment",
            hideNumber: true,
        },
        {
            name: "Agree",
            title: "Do you agree with terms and conditions?",
            type: "boolean",
            hideNumber: true,
        },
        {
            type: "panel",
            name: "ratingPanel",
            elements: [
                {
                    name: "Rating",
                    title: "Rate our service:",
                    type: "rating",
                    description: "1 - bad 5 - excellent",
                    minRateDescription: "Poor",
                    maxRateDescription: "Excellent",
                    hideNumber: true,
                },
                {
                    type: "html",
                    name: "question6",
                    html: "<button class='border-2 bg-white' onclick=`alert(123)`>Не взаимодействую</button>",
                },
            ],
        },
        {
            name: "Upload",
            title: "Upload your profile picture:",
            type: "file",
            hideNumber: true,
        },
    ],
};

function App({ surveyJson }) {
    console.log(surveyJson);
    const updatedSurveyJson = JSON.parse(JSON.stringify(surveyJson));

    updatedSurveyJson.showTitle = false;
    updatedSurveyJson.completeText = "Отправить";
    updatedSurveyJson.completedHtml =
        "<h3>Спасибо за участие в опросе!</h3><p>Ваши ответы были успешно отправлены.</p>";
    if (!updatedSurveyJson.elements && updatedSurveyJson.questions) {
        updatedSurveyJson.elements = updatedSurveyJson.questions;
    }
    updatedSurveyJson.elements?.forEach((element) => {
        const type = getCorrectTypes(element.type);
        element.type = type;
    });

    const survey = new Model(updatedSurveyJson);
    survey.applyTheme(customSurvey);

    return (
        <>
            {updatedSurveyJson.cover ? (
                <div className="self-stretch flex-grow-0 flex-shrink-0 h-[200px] relative overflow-hidden rounded-2xl bg-[#ebf5ff]">
                    <img
                        src={updatedSurveyJson.cover}
                        className="w-[748px] h-[748px] absolute left-[-1px] top-[-170px] object-cover"
                    />
                </div>
            ) : (
                ""
            )}
            <p className="flex-grow-0 flex-shrink-0 w-[948] text-3xl font-medium text-center text-[#1f2a37] p-8">
                <span className="flex-grow-0 flex-shrink-0 w-[948] text-3xl font-medium text-center text-[#1f2a37]">
                    {updatedSurveyJson.title}
                </span>
                <br />
                {/* <span className="flex-grow-0 flex-shrink-0 w-[948] text-3xl font-medium text-center text-[#1f2a37]">
                    Отдел персонала
                </span> */}
            </p>
            <div
                className="flex flex-grow-0 flex-shrink-0 w-[948px] p-1 rounded-2xl bg-white"
                style={{
                    boxShadow:
                        "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                }}
            >
                <Survey model={survey} />
            </div>
        </>
    );
}

export default App;

const rootContainer =
    document.getElementById("root") || document.getElementById("survey-data");

if (rootContainer) {
    let surveyJson;
    if (rootContainer.id === "survey-data") {
        surveyJson = JSON.parse(rootContainer.dataset.survey);
    }
    createRoot(rootContainer).render(
        <React.StrictMode>
            <App surveyJson={surveyJson} />
        </React.StrictMode>
    );
}

const newSurveyRoot = document.getElementById("newSurveyRoot");
if (newSurveyRoot) {
    const surveyData = newSurveyRoot.dataset.survey
        ? JSON.parse(newSurveyRoot.dataset.survey)
        : {};
    const isAdmin =
        newSurveyRoot.dataset.user === "true" || surveyData?.is_admin;
    const editMode = newSurveyRoot.dataset.editMode === "true";
    createRoot(newSurveyRoot).render(
        <React.StrictMode>
            <NewSurveyComponent
                isAdmin={isAdmin}
                surveyData={surveyData}
                editMode={editMode}
            />
        </React.StrictMode>
    );
}

window.Alpine = Alpine;

Alpine.start();
