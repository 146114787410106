const DragIconHorizontalSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative cursor-grab"
            preserveAspectRatio="none"
        >
            <path
                d="M15.1667 7.08301C15.1667 7.77337 15.7263 8.33301 16.4167 8.33301C17.107 8.33301 17.6667 7.77337 17.6667 7.08301C17.6667 6.39265 17.107 5.83301 16.4167 5.83301C15.7263 5.83301 15.1667 6.39265 15.1667 7.08301ZM9.75002 7.08301C9.75002 7.77337 10.3097 8.33301 11 8.33301C11.6904 8.33301 12.25 7.77337 12.25 7.08301C12.25 6.39265 11.6904 5.83301 11 5.83301C10.3097 5.83301 9.75002 6.39265 9.75002 7.08301ZM5.58335 8.33301C4.89302 8.33301 4.33335 7.77337 4.33335 7.08301C4.33335 6.39265 4.89302 5.83301 5.58335 5.83301C6.27369 5.83301 6.83335 6.39265 6.83335 7.08301C6.83335 7.77337 6.27369 8.33301 5.58335 8.33301ZM15.1667 12.9163C15.1667 13.6067 15.7263 14.1663 16.4167 14.1663C17.107 14.1663 17.6667 13.6067 17.6667 12.9163C17.6667 12.226 17.107 11.6663 16.4167 11.6663C15.7263 11.6663 15.1667 12.226 15.1667 12.9163ZM11 14.1663C10.3097 14.1663 9.75002 13.6067 9.75002 12.9163C9.75002 12.226 10.3097 11.6663 11 11.6663C11.6904 11.6663 12.25 12.226 12.25 12.9163C12.25 13.6067 11.6904 14.1663 11 14.1663ZM4.33335 12.9163C4.33335 13.6067 4.89302 14.1663 5.58335 14.1663C6.27369 14.1663 6.83335 13.6067 6.83335 12.9163C6.83335 12.226 6.27369 11.6663 5.58335 11.6663C4.89302 11.6663 4.33335 12.226 4.33335 12.9163Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default DragIconHorizontalSVG;
