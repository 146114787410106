const BasketIconSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M11.3333 4.00016H14.6666V5.3335H13.3333V14.0002C13.3333 14.3684 13.0348 14.6668 12.6666 14.6668H3.33331C2.96513 14.6668 2.66665 14.3684 2.66665 14.0002V5.3335H1.33331V4.00016H4.66665V2.00016C4.66665 1.63198 4.96513 1.3335 5.33331 1.3335H10.6666C11.0348 1.3335 11.3333 1.63198 11.3333 2.00016V4.00016ZM12 5.3335H3.99998V13.3335H12V5.3335ZM5.99998 7.3335H7.33331V11.3335H5.99998V7.3335ZM8.66665 7.3335H9.99998V11.3335H8.66665V7.3335ZM5.99998 2.66683V4.00016H9.99998V2.66683H5.99998Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default BasketIconSVG;
