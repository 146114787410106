const EditIconSVG = ({ onClick }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            onClick={onClick}
        >
            <path
                d="M10.4853 6.38419L9.54247 5.44138L3.33333 11.6505V12.5933H4.27614L10.4853 6.38419ZM11.4281 5.44138L12.3709 4.49858L11.4281 3.55576L10.4853 4.49858L11.4281 5.44138ZM4.82843 13.9267H2V11.0982L10.9567 2.14155C11.2171 1.8812 11.6391 1.8812 11.8995 2.14155L13.7851 4.02717C14.0455 4.28752 14.0455 4.70963 13.7851 4.96998L4.82843 13.9267Z"
                fill="#6B7280"
            />
        </svg>
    );
};

export default EditIconSVG;
