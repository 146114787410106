const MinusIconSVG = ({ onClick }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
            preserveAspectRatio="xMidYMid meet"
            role="button"
            onClick={onClick}
        >
            <path
                d="M7.99998 14.6668C4.31808 14.6668 1.33331 11.682 1.33331 8.00016C1.33331 4.31826 4.31808 1.3335 7.99998 1.3335C11.6818 1.3335 14.6666 4.31826 14.6666 8.00016C14.6666 11.682 11.6818 14.6668 7.99998 14.6668ZM7.99998 13.3335C10.9455 13.3335 13.3333 10.9457 13.3333 8.00016C13.3333 5.05464 10.9455 2.66683 7.99998 2.66683C5.05446 2.66683 2.66665 5.05464 2.66665 8.00016C2.66665 10.9457 5.05446 13.3335 7.99998 13.3335ZM4.66665 7.3335H11.3333V8.66683H4.66665V7.3335Z"
                fill="#E02424"
            ></path>
        </svg>
    );
};

export default MinusIconSVG;
