const SmallTextIconSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M1.66667 3.33333C1.66667 2.8731 2.03977 2.5 2.5 2.5H17.5C17.9603 2.5 18.3333 2.8731 18.3333 3.33333V16.6667C18.3333 17.1269 17.9603 17.5 17.5 17.5H2.5C2.03977 17.5 1.66667 17.1269 1.66667 16.6667V3.33333ZM3.33334 4.16667V15.8333H16.6667V4.16667H3.33334ZM5.83334 6.66667H14.1667V9.16667H12.5V8.33333H10.8333V11.6667H12.0833V13.3333H7.91667V11.6667H9.16667V8.33333H7.5V9.16667H5.83334V6.66667Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default SmallTextIconSVG;
