import React, { useState } from "react";

const ButtonComponent = ({
    onClick,
    icon: Icon,
    text,
    textClass,
    required = false,
    alwaysActive = false, // Новый пропс для отключения ховера
}) => {
    const [hovered, setHovered] = useState(false);

    return (
        <div
            role="button"
            onClick={onClick}
            className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1 px-2 py-1 rounded-lg"
            style={{
                borderRadius:
                    alwaysActive || hovered
                        ? "var(--rounded-lg, 8px)"
                        : "initial",
                background:
                    alwaysActive || hovered
                        ? "var(--primary-200, rgba(191, 27, 44, 0.08))"
                        : "initial",
            }}
            // Отключаем hover-события, если alwaysActive=true
            onMouseEnter={() => !alwaysActive && setHovered(true)}
            onMouseLeave={() => !alwaysActive && setHovered(false)}
        >
            <Icon required={required} />
            <p
                className={`flex-grow-0 flex-shrink-0 text-xs text-left ${textClass}`}
            >
                {text}
            </p>
        </div>
    );
};

export default ButtonComponent;
