const StarIconSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M10.0005 15.2167L4.12263 18.5069L5.4354 11.9L0.489914 7.32658L7.17911 6.53346L10.0005 0.416748L12.8218 6.53346L19.511 7.32658L14.5656 11.9L15.8783 18.5069L10.0005 15.2167ZM10.0005 13.3067L13.5394 15.2877L12.749 11.3098L15.7266 8.55625L11.6992 8.0787L10.0005 4.39593L8.30179 8.0787L4.27435 8.55625L7.25193 11.3098L6.46153 15.2877L10.0005 13.3067Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default StarIconSVG;
