const ArrowsUpDownIconSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-5 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M15 7.5L10 2.5L5 7.5H15ZM15 12.5L10 17.5L5 12.5H15Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default ArrowsUpDownIconSVG;
