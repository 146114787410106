import React, { useState, useEffect } from "react";
import { useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SurveyEditor from "./SurveyEditor";
import { useSurveyStore } from "./stores/useSurveyStore";

const DraggableArea = ({ handleConditionsModalOpenClick }) => {
    const { droppedItems: items, setDroppedItems: setItems } = useSurveyStore();
    const { items: allItems } = useSurveyStore();

    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

    useEffect(() => {
        setHoveredItemIndex(null);
    }, [items]);

    const [{ isOver }, drop] = useDrop({
        accept: ["ITEM", "EXISTING_ITEM"],
        drop: (item, monitor) => {
            if (monitor.getItemType() === "ITEM") {
                addItemToContainer(item.id);
            } else if (monitor.getItemType() === "EXISTING_ITEM") {
                // console.log("EXISTING_ITEM", item);

                const { index: dragIndex } = item;
                if (hoveredItemIndex !== null) {
                    moveItem(dragIndex, hoveredItemIndex);
                }
            }
        },
        hover: (item, monitor) => {
            if (monitor.getItemType() === "EXISTING_ITEM") {
                // console.log("hover", item);
                setHoveredItemIndex(item.index);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const addItemToContainer = (id) => {
        const el = {
            type_id: id,
            id: `sk-${Date.now()}`,
        };
        setItems([...items, el]);
    };

    const moveItem = (dragIndex, hoverIndex) => {
        // console.log("items", items);

        if (dragIndex === hoverIndex || hoverIndex === null) return;

        const newItems = [...items];
        const [draggedItem] = newItems.splice(dragIndex, 1);

        newItems.splice(hoverIndex, 0, draggedItem);
        setItems(newItems);
    };

    const getItemById = (id) => {
        return allItems?.find((item) => item.id === id);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div ref={drop} className="min-h-80">
                {!items?.length ? (
                    <div className="flex flex-col justify-center items-center w-[596px] h-[228px] gap-2.5 rounded-lg bg-gray-50 border-2 border-gray-200 border-dashed">
                        <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 gap-4">
                            <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[7px]">
                                <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M27.845 10.6728L21.1783 3.83543C21.0235 3.67625 20.8396 3.54995 20.6371 3.46378C20.4346 3.37761 20.2175 3.33325 19.9983 3.33325C19.7791 3.33325 19.562 3.37761 19.3595 3.46378C19.1571 3.54995 18.9731 3.67625 18.8183 3.83543L12.1516 10.6728C11.8391 10.9937 11.6637 11.4289 11.6641 11.8825C11.6644 12.3361 11.8404 12.771 12.1533 13.0915C12.4663 13.412 12.8906 13.5919 13.3328 13.5915C13.7751 13.5912 14.1991 13.4107 14.5116 13.0898L18.3333 9.17026V23.8466C18.3333 24.2999 18.5089 24.7347 18.8215 25.0553C19.134 25.3758 19.558 25.5559 20 25.5559C20.442 25.5559 20.8659 25.3758 21.1785 25.0553C21.4911 24.7347 21.6666 24.2999 21.6666 23.8466V9.17026L25.4883 13.0898C25.8027 13.4011 26.2237 13.5734 26.6606 13.5695C27.0976 13.5656 27.5157 13.3859 27.8247 13.0689C28.1337 12.752 28.309 12.3233 28.3128 11.8751C28.3166 11.4269 28.1486 10.9951 27.845 10.6728Z"
                                        fill="#9CA3AF"
                                    ></path>
                                    <path
                                        d="M33.3333 22.9919H25V23.8466C25 25.2066 24.4732 26.5109 23.5355 27.4726C22.5978 28.4343 21.3261 28.9746 20 28.9746C18.6739 28.9746 17.4021 28.4343 16.4644 27.4726C15.5268 26.5109 15 25.2066 15 23.8466V22.9919H6.66665C5.78259 22.9919 4.93474 23.3521 4.30962 23.9932C3.6845 24.6344 3.33331 25.5039 3.33331 26.4106V33.2479C3.33331 34.1546 3.6845 35.0242 4.30962 35.6653C4.93474 36.3064 5.78259 36.6666 6.66665 36.6666H33.3333C34.2174 36.6666 35.0652 36.3064 35.6903 35.6653C36.3155 35.0242 36.6666 34.1546 36.6666 33.2479V26.4106C36.6666 25.5039 36.3155 24.6344 35.6903 23.9932C35.0652 23.3521 34.2174 22.9919 33.3333 22.9919ZM29.1666 33.2479C28.6722 33.2479 28.1888 33.0975 27.7777 32.8158C27.3666 32.5341 27.0462 32.1336 26.857 31.6651C26.6677 31.1966 26.6182 30.6811 26.7147 30.1837C26.8111 29.6863 27.0492 29.2295 27.3989 28.8709C27.7485 28.5123 28.194 28.2681 28.6789 28.1692C29.1639 28.0703 29.6665 28.121 30.1234 28.3151C30.5802 28.5092 30.9706 28.8378 31.2453 29.2594C31.52 29.6811 31.6666 30.1768 31.6666 30.6839C31.6666 31.3639 31.4033 32.0161 30.9344 32.4969C30.4656 32.9778 29.8297 33.2479 29.1666 33.2479Z"
                                        fill="#9CA3AF"
                                    ></path>
                                </svg>
                                <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-gray-500">
                                    Добавьте сюда вопросы формы
                                </p>
                            </div>
                            <div
                                className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 px-3 py-2 rounded-lg bg-gray-200"
                                role="button"
                                onClick={() =>
                                    alert("У вас пока нет созданных опросов")
                                }
                            >
                                <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#1f2a37]">
                                    Использовать созданный опрос
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="h-100 flex flex-col gap-6">
                        {items?.map((el, index) => {
                            const item = getItemById(el.type_id);
                            return (
                                <SurveyEditor
                                    key={el.id}
                                    id={el.id}
                                    type={item?.type}
                                    index={index}
                                    moveItem={moveItem}
                                    tempItem={el}
                                    isRequired={el.required}
                                    handleConditionsModalOpenClick={
                                        handleConditionsModalOpenClick
                                    }
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </DndProvider>
    );
};

export default DraggableArea;
