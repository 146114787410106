import React from "react";
import Select, { components } from "react-select";
import SearchIconSVG from "./icons/SearchIconSVG";
import CloseIconSmallSVG from "./icons/CloseIconSmallSVG";

const CustomClearIndicator = (props) => (
    <components.ClearIndicator {...props}>
        <div className="px-4" role="button">
            <CloseIconSmallSVG />
        </div>
    </components.ClearIndicator>
);

const CustomSelect = ({
    options,
    value,
    onChange,
    placeholder,
    menuPlacement = "auto",
}) => {
    return (
        <Select
            options={options}
            isSearchable={true}
            isClearable={true}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            isMulti={true}
            className="self-stretch w-[348px] min-h-[47px] mb-2 border-0"
            styles={{
                control: (base, state) => ({
                    ...base,
                    borderRadius: "8px",
                    paddingLeft: "16px",
                    backgroundColor: "#f9fafb",
                    borderWidth: 0,
                    borderColor: state.isFocused ? "#bf1b2c" : "#e5e7eb",
                    minHeight: "47px",
                    boxShadow: state.isFocused ? "0 0 0 1px #bf1b2c" : "none",
                    "&:hover": {
                        borderColor: "#bf1b2c",
                    },
                }),
                input: (base) => ({
                    ...base,
                    "input:focus": {
                        boxShadow: "none",
                        borderColor: "#bf1b2c",
                    },
                }),
                placeholder: (base) => ({
                    ...base,
                    color: "#9ca3af",
                }),
                option: (base, { isFocused }) => ({
                    ...base,
                    backgroundColor: isFocused ? "#FDE8E8" : "#fff",
                    color: "#111928",
                    "&:hover": {
                        backgroundColor: "#FDE8E8",
                    },
                }),
                menuList: (base) => ({
                    ...base,
                    maxHeight: "170px", // Ограничиваем высоту выпадающего списка
                    overflowY: "auto", // Добавляем прокрутку
                }),
            }}
            components={{
                Control: ({ children, ...rest }) => (
                    <components.Control {...rest}>
                        <SearchIconSVG />
                        {children}
                    </components.Control>
                ),
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                ClearIndicator: CustomClearIndicator,
            }}
            menuPlacement={menuPlacement}
        />
    );
};

export default CustomSelect;
