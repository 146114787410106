const CopyIconSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M4.66653 4.00016V2.00016C4.66653 1.63198 4.96501 1.3335 5.3332 1.3335H13.3332C13.7014 1.3335 13.9999 1.63198 13.9999 2.00016V11.3335C13.9999 11.7017 13.7014 12.0002 13.3332 12.0002H11.3332V13.9996C11.3332 14.3681 11.0333 14.6668 10.662 14.6668H2.67111C2.30039 14.6668 2 14.3704 2 13.9996L2.00173 4.66741C2.0018 4.2989 2.30176 4.00016 2.67295 4.00016H4.66653ZM3.33495 5.3335L3.33346 13.3335H9.99987V5.3335H3.33495ZM5.99987 4.00016H11.3332V10.6668H12.6665V2.66683H5.99987V4.00016Z"
                fill="#6B7280"
            ></path>
        </svg>
    );
};

export default CopyIconSVG;
